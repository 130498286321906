<template>
  <div class="group-dialog">
    <b-modal id="modalModifyGroup" modal-class="dialog-1200 custom-dialog no-footer modify-group" centered ok-only ok-title="Actualizar" ok-variant="warning"
    title="Modificar grupo" @close="confirmClose" @ok="ok" no-close-on-esc no-close-on-backdrop :ok-disabled="loading">
      <div class="mx-1 mt-1 mb-05">
        <form-group @send="onAccept" :key="keyFormRender" :item.sync="item" :data.sync="group" :modified.sync="modified" origin="rule" ref="formGroup"></form-group>
      </div>
    </b-modal>
  </div>
</template>
<script>
import FormGroup from '../create/FormGroup'
export default {
  components: {FormGroup},
  props: ['active', 'group', 'item'],
  data () {
    return {
      keyFormRender: 0,
      modified: false,
      loading: false
    }
  },
  watch: {
    loading () {
      this.$refs.formGroup.loading = this.loading
    }
  },
  methods: {
    ok (e) {
      e.preventDefault()
      this.$refs.formGroup.callSubmitFormRender()
    },
    confirmClose (bvModalEvt) {
      if (this.modified) {
        bvModalEvt.preventDefault()
        this.$confirm('¿Está seguro que desea salir sin guardar los cambios?', (_) => this.$bvModal.hide('modalModifyGroup'), 'Tienes cambios sin guardar', 'Si, quiero salir', 'Seguir modificando')
      }
    },
    onAccept (form) {
      this.loading = true
      const payload = {
        variable: this.group.variable,
        type: this.group.type,
        values: form.values,
        name: form.group_name,
        description: form.group_desc
      }
      this.$emit('result', {payload, id: this.group.id})
    }
  }
}
</script>
<style>
  .modify-group > .vs-dialog > .vs-dialog-text {
    overflow-y: scroll;
    position: inherit;
    max-height: calc(100vh - 250px);
  }
</style>