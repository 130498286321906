<template>
  <div v-if="viewData">
    <b-card>
      <filter-swapper :trigger="selectedRows.length === 0" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :fields="fields" :form.sync="form" @send="onSearch" :buttonSend="buttonSend"
            containerButtonsClass="col-sm-12 col-md-4 container-button mt-2" class="mb-2">
            <template v-slot:buttons>
              <b-button variant="outline-light" @click="cleanSearch" class="ml-2" title="Limpiar filtros" v-b-tooltip.hover><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="!loading.first">
        <table-render id="table-groups" :loading="loading.groups" :schema="schema" :rows="rows" :actions="actions" :showCheckboxes="true" :selectedRows.sync="selectedRows"/>
        <pagination :pagination="pagination"/>
        <modal-modify-group ref="modalModifyGroup" :item="item" :group="groupModify" @result="updateGroup"></modal-modify-group>
        <modal-show-group :group="groupShow" :fxArray="fxArray"></modal-show-group>
      </div>
      <b-skeleton v-show="loading.first" type="input" class="mt-2 mb-2 spacing-label-field" height="40px"/>
      <div class="table-render-skeleton" v-if="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalModifyGroup from './ModalModifyGroup.vue'
import ModalShowGroup from './ModalShowGroup.vue'

// import cards from '@/const-data/groups/cards'
import relationTypeItems from '@/const-data/groups/relation-type-item'

import TicketManagementService from '../../ticketManagement.service.js'

export default {
  components: {ModalModifyGroup, ModalShowGroup},
  data () {
    return {
      fields: [],
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      schema: [],
      rows: [],
      actions: [],
      groups: [],
      form: {},
      keyFormRender: 0,
      keyTableRender: 0,
      loading: {
        first: true,
        groups: false
      },
      cards: [],
      selectedRows: [],
      openModifyModal: false,
      openShowModal: false,
      groupModify: null,
      groupShow: null,
      item: null,
      pagination: {
        page: 1,
        total: 1,
        limit: 10
      },
      viewData: false,
      ticketManagementService: new TicketManagementService(this),
      fxArray: {}
    }
  },
  watch: {
    'pagination.page' () {
      // Buscar nuevos tickets según página seleccionada
      this.setParamsGetGroups()
    },
    'pagination.limit' () {
      // Buscar nuevos tickets según página seleccionada
      this.setParamsGetGroups()
    }
  },
  beforeMount () {
    this.getConditions()
  },
  async mounted () {
    this.schema = [
      {label: 'Nombre', key: 'name', sortable: true},
      {label: 'Descripción', key: 'description', sortable: true},
      {label: 'Categoría', key: 'category', sortable: true},
      {label: 'Variable', key: 'item', sortable: true},
      {label: 'Fecha creación', key: 'created_at_format', sortable: true},
      {label: 'Acciones', key: 'actions', class: 'text-center'}
    ]

    this.actions = [
      {action: id => this.openModalShowGroup(id), icon: 'EyeIcon', color:'info', text:'Ver grupo'},
      {action: id => this.openModalModifyGroup(id), icon: 'Edit2Icon', color:'success', text:'Editar'},
      {action: id => this.confirmDelete(id), icon: 'TrashIcon', color:'danger', text:'Eliminar'}
    ]
    this.buttons = [
      { name: 'delete', text: 'Eliminar seleccionados', color: 'danger',  icon: 'TrashIcon', action: this.confirmDeleteMultiple}
    ]
  },
  methods: {
    getConditions() {
      const arrServices = [
        {name: 'getConditions'}
      ]
      this.ticketManagementService.callMultipleServices(arrServices, true)
        .then(response => {
          this.cards = response.getConditions.data
          this.viewData = true
          this.initFilters()
          this.searchFx()
        })
        .catch(err => {
          console.error(err)
        })
    },
    searchFx() {
      if (this.cards.length > 0) {
        this.cards.forEach(card => {
          if (!!card.fx) {
            this.ticketManagementService.callService(card.fx, {limit:9999, page:1})
              .then(response => {
                this.fxArray[card.fx] = response.data
              })
              .catch(err => {
                console.error(err)
              })
          }
        })
      }
    },
    initFilters () {
      const optionsVariable = this.cards.filter(({parent}) => parent).map(card => ({...card, text: card.title}))
      this.fields = [
        // {fieldType: 'FieldSelect', name: 'category', label: 'Categoría', containerClass: 'col-sm-12 col-md-3 container-info', options: optionsCategory, change: this.selectCategory},
        {fieldType: 'FieldSelect', name: 'variable', label: 'Variable', options: optionsVariable, searchOnType: { nChars: 999}},
        {fieldType: 'FieldInput', name: 'name', label: 'Nombre grupo'}
      ]
      this.keyFormRender++
      this.setParamsGetGroups()
    },
    cleanSearch (e) {
      const indexVariable = this.fields.findIndex(({name}) => name === 'variable')
      this.fields[indexVariable].validation = ''
      this.form = {}
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.setParamsGetGroups()
    },
    selectCategory (fieldName, value) {
      const indexVariable = this.fields.findIndex(({name}) => name === 'variable')
      this.form['variable'] = undefined
      this.form['group'] = undefined
      if (value && value.id) {
        this.fields[indexVariable].options = this.cards.filter(({parent, id}) => !!parent && value.id === parent).map(card => ({...card, text: card.title}))
        this.fields[indexVariable].validation = 'required'
      } else {
        this.fields[indexVariable].validation = ''
      }
      this.keyFormRender++
    },
    openModalShowGroup (id) {
      this.groupShow = this.rows.filter(group => parseInt(group.id) === parseInt(id))[0]
      this.$bvModal.show('modalShowGroup')
    },
    openModalModifyGroup (idGroup) {
      this.groupModify = this.rows.filter(group => parseInt(group.id) === parseInt(idGroup))[0]
      this.item = this.cards.filter(({id}) => id === this.groupModify.variable)[0]
      if (this.item && this.groupModify && Object.keys(relationTypeItems).map(key => relationTypeItems[key].type).includes(this.groupModify.type)) {
        this.$bvModal.show('modalModifyGroup')
      } else {
        this.$alert('Los datos del grupo no pudieron ser cargados debido a un error en la información vigente.', null, 'Problema')
      }
    },
    setLoading (bool, text) {
      if (bool) {
        this.loading.groups = bool
      } else {
        this.loading.groups = bool
        this.keyTableRender++
      }
    },
    onSearch (form) {
      this.form = {...form}
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.setParamsGetGroups()
    },
    getValuesFilter () {
      const filter = {}
      if (this.form && this.form.name) filter.name = this.form.name
      if (this.form && this.form.variable) filter.variable = this.form.variable.id
      return filter
    },
    setParamsGetGroups () {
      this.selectedRows = []
      const queryParams = {
        page: this.pagination.page,
        limit: this.pagination.limit,
        ...this.getValuesFilter()
      }
      this.getGroups(queryParams)
    },
    getGroups (queryParams) {
      this.setLoading(true)
      this.ticketManagementService.callService('getAllGroups', queryParams)
        .then(response => {
          this.pagination.total = response.paginationParams.totalRegisters
          const rows = response.data.map(group => {
            const currentItemVariable = this.cards.filter(({id}) => id === group.variable)[0]
            const currentCategoryVariable = currentItemVariable && currentItemVariable.id ? this.cards.filter(({id}) => id === currentItemVariable.parent)[0] : null
            return {
              ...group, 
              type_text: currentItemVariable && currentItemVariable.type ? relationTypeItems[currentItemVariable.type].text : null,
              item: currentItemVariable && currentItemVariable.title ? currentItemVariable.title : null,
              category: currentCategoryVariable ? currentCategoryVariable.title : null,
              created_at_format: this.$options.filters.moment(group.created_at, 'DD-MM-YYYY hh:mm'),
              values_text: JSON.stringify(group.values),
              full_variable: currentItemVariable
            }
          })
          this.rows = rows.map(group => {
            group.typeof_value = this.ticketManagementService.validateNullVariable(group)
            return group
          })
        })
        .catch(err => {
          const text = err.message ? err.message : err.code === 404 ? 'No se encontraron grupos para los filtros ingresados' : 'Ocurrió un problema al cargar los grupos'
          this.$alert(text)
        })
        .finally(end => {
          this.loading.first = false
          this.setLoading(false)
        })
    },
    validateNullVariable (group) {
      const isArray = this.isArray(group)
      if (!group.values) {
        // Valor del grupo es nulo
        return 'nullable'
      } else if (isArray && group.values.length > 0) {
        // Variable es tipo arreglo y tiene valores
        return 'array'
      } else if (group.full_variable && (!!group.values.from || group.values.from === 0) && !!group.values.to) {
        // Variable es tipo rango y tiene valores en from y to
        return 'range'
      } else if (isArray && group.values.length === 0) {
        // Variable es tipo arreglo y no tiene valores
        return 'nullable'
      } else if (group.full_variable && !group.values.from && !group.values.to) {
        // Variable es tipo rango y no tiene valores en from ni to
        return 'nullable'
      } else {
        // Variable no válida
        return 'invalid'
      }
    },
    isArray (group) {
      return group.full_variable && ['number-splitted', 'text-splitted', 'text', 'text-large', 'list'].includes(group.full_variable.type)
    },
    openModalAddTicket (id) {
      this.selectedRows = []
      this.form = this.rows.filter(group => group.id === id)[0]
      this.dialogOpenAddAnswerTicket = true
    },
    confirmDeleteMultiple () {
      this.$yesno('¿Está seguro que desea eliminar estos grupos?', this.deleteMultipleGroup)
    },
    deleteMultipleGroup () {
      this.setLoading(true, 'Eliminando grupos')
      const arrayDeletion = this.selectedRows.map(id => {
        return {name: 'deleteGroup', params: {id}}
      })
      this.ticketManagementService.callMultipleServices(arrayDeletion)
        .then(response => {
          this.$success('Los grupos seleccionados han sido eliminados correctamente')
        })
        .catch(err => {
          console.error(err)
          this.$alert('No se pudieron completar todas las eliminaciones')
        })
        .finally(end => {
          this.selectedRows = []
          this.setParamsGetGroups()
        })
    },
    confirmDelete (id) {
      this.$yesno('¿Está seguro que desea eliminar estos grupos?', (_) => this.deleteGroup(id))
    },
    deleteGroup (id) {
      this.setLoading(true, 'Eliminando grupo')
      this.ticketManagementService.callService('deleteGroup', null, {id})
        .then(response => {
          this.$success('El grupo ha sido eliminado correctamente')
          this.rows = this.rows.filter(group => parseInt(group.id) !== parseInt(id))
        })
        .catch(err => {
          console.error(err)
          this.$alert('Ocurrió un problema al eliminar el grupo')
        })
        .finally(end => {
          this.setLoading(false)
        })
    },
    updateGroup (data) {
      this.setLoading(true, 'Actualizando grupo')
      const index = this.rows.findIndex(group => group.id === data.id)
      this.ticketManagementService.callService('updateGroup', data.payload, {id: data.id})
        .then(response => {
          const currentAnswer = {...this.rows[index], ...data.payload, values_text: JSON.stringify(data.payload.values)}
          currentAnswer.typeof_value = this.ticketManagementService.validateNullVariable(currentAnswer)
          this.rows[index] = currentAnswer
          this.rows = [...this.rows]
          this.$success('El grupo ha sido modificado correctamente')
          this.$bvModal.hide('modalModifyGroup')
        })
        .catch(err => {
          console.error(err)
          this.$alert('Ocurrió un problema al guardar el grupo')
        })
        .finally(end => {
          this.setLoading(false)
          this.$refs.modalModifyGroup.loading = false
        })
    }
  }
}
</script>

<style>
</style>
