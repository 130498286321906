<template>
  <div class="group-dialog">
    <b-modal id="modalShowGroup" class="dialog-500 custom-dialog" ok-variant="outline-success" modal-class="modal-success" centered
    ok-only :ok-title="$t('Cerrar')" title="Detalle grupo">
      <div v-if="group && group.id">
        <div class="row">
          <div class="col-sm-12">
            <b-alert v-if="!group.full_variable" color="danger">
              <span>Los datos del grupo no pudieron ser cargados completamente debido a un error en la información vigente.</span>
            </b-alert>
          </div>
          <div class="col-sm-6 container-info mb-1">
            <label>{{$t('Categoría')}}</label>
            <span>{{group.category || '--'}}</span>
          </div>
          <div class="col-sm-6 container-info mb-1">
            <label>{{$t('Variable')}}</label>
            <span>{{group.item || group.variable}}</span>
          </div>
          <div class="col-sm-6 container-info mb-1">
            <label>{{$t('Nombre')}}</label>
            <span>{{group.name}}</span>
          </div>
          <div class="col-sm-6 container-info mb-1">
            <label>{{$t('Tipo')}}</label>
            <span>{{group.type_text || group.type}}</span>
          </div>
          <div class="col-sm-12 container-info mb-1">
            <label>{{$t('Descripción')}}</label>
            <span>{{group.description}}</span>
          </div>
          <div class="col-sm-12 container-info mb-1">
            <label>{{$t('Valores')}}</label>
            <span v-if="group.typeof_value === 'array' || (group.values && group.values.length > 0)">
              <ul><li v-for="(value, index) of group.values" :key="index">{{textValue(value)}}<em v-if="textValue(value) !== value"> ({{ value }})</em><br></li></ul>
            </span>
            <span v-else-if="group.typeof_value === 'range'">{{$t('Desde')}} <b>{{group.values.from}}</b>{{ group.values.include_from ? ' ('+$t('Incluido')+')' : '' }} {{$t('hasta')}} <b>{{group.values.to}}</b>{{ group.values.include_to ? ' ('+$t('Incluido')+')' : '' }}</span>
            <span v-else-if="group.typeof_value === 'nullable'">{{$t('Solo valores nulos o vacíos')}}</span>
            <pre v-else>{{group.values}}</pre>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'modal-show-group',
  props: ['group', 'active', 'fxArray'],
  methods: {
    textValue(value) {
      const fxArrayFilter = !!this.group
                            && !!this.group.full_variable
                            && !!this.group.full_variable.fx
                            && !!this.fxArray
                            && !!this.fxArray[this.group.full_variable.fx] ? this.fxArray[this.group.full_variable.fx].filter(element => element.code === value)[0] : {}
      return fxArrayFilter.complete_name || fxArrayFilter.name || value
    }
  }
}
</script>
<style lang="">
    
</style>